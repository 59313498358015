import React from "react";
import ReactExport from "react-data-export";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";

import { formatDate } from "@/lib/date";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TitleButton = styled(Button)`
  margin-left: 5px;
`;

// const SPOT_CODE_10163 = "10163";

export default function ExcelForPrint({ data, spotCode }) {
  data.forEach((c, i) => {
    c.index = i + 1;
  });

  return (
    <ExcelFile
      filename={`체인로지스_두발히어로_송장출력_${formatDate(
        new Date(),
        "MMdd",
      )}`}
      element={
        <TitleButton type="button" variant="outline-secondary" width="120px">
          송장 출력용 엑셀
        </TitleButton>
      }
    >
      <ExcelSheet data={data} name="ExcelForPrint">
        <ExcelColumn
          label="출력번호"
          value={(col) => {
            return `${col.index}/${data.length}`;
          }}
        />
        <ExcelColumn label="예약번호" value="bookId" />
        <ExcelColumn label="사측주문번호" value="orderIdFromCorp" />
        {/* {spotCode === SPOT_CODE_10163 && (
          <ExcelColumn label="주문상세번호" value="orderDetailNumber" />
        )} */}
        <ExcelColumn label="송하인명" value="senderName" />
        <ExcelColumn label="송하인주소" value="senderAddress" />
        <ExcelColumn label="송하인상세주소" value="senderAddressDetail" />
        <ExcelColumn label="송하인연락처" value="senderMobile" />
        <ExcelColumn label="수하인명" value="receiverName" />
        <ExcelColumn label="수하인주소" value="receiverAddress" />
        <ExcelColumn label="수하인상세주소" value="receiverAddressDetail" />
        <ExcelColumn label="수하인연락처1" value="receiverMobile" />
        <ExcelColumn label="수하인연락처2" value="receiverMobile2" />
        <ExcelColumn label="고객요청사항" value="memoFromCustomer" />
        <ExcelColumn label="기타사항1" value="etc1" />
        <ExcelColumn label="기타사항2" value="etc2" />
        <ExcelColumn label="기타사항3" value="etc3" />
        <ExcelColumn label="결제방법" value="paymentMethod" />
        <ExcelColumn label="물품가격" value="productPrice" />
        <ExcelColumn label="물품분류" value="productCategory" />
        <ExcelColumn label="물품명" value="productName" />
        {/* {spotCode === SPOT_CODE_10163 ? (
          <ExcelColumn
            label="물품명1"
            value={(col) => col.productName[0] || ""}
          />
        ) : null}
        <ExcelColumn label="물품명1" value="productName" />
        <ExcelColumn label="물품명2" value="productName" />
        <ExcelColumn label="물품명3" value="productName" />
        <ExcelColumn label="물품명4" value="productName" />
        <ExcelColumn label="물품명5" value="productName" />
        <ExcelColumn label="물품명6" value="productName" />
        <ExcelColumn label="물품명7" value="productName" />
        <ExcelColumn label="물품명8" value="productName" />
        <ExcelColumn label="물품명9" value="productName" />
        <ExcelColumn label="물품명10" value="productName" /> */}
        <ExcelColumn label="지역그룹" value="receiverAllocationGroupName" />
      </ExcelSheet>
    </ExcelFile>
  );
}
