import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import api from "../Api";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
};

const INITIAL_STATE = { agPostalCodes: [] };

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        agPostalCodes: action.agPostalCodes,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useAgPostalCodesStore = () => {
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const { items } = await api.get("/ag-postal-codes/all");

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      agPostalCodes: items,
    });
  }, [dispatch]);

  return {
    state,
    fetchAll,
  };
};
