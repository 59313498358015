import BaseModel from "./base-model";

export default class DeliveryForExcel extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  get etc4Obj() {
    try {
      return this.etc4 && JSON.parse(this.etc4);
    } catch (e) {
      return {};
    }
  }

  get orderDetailNumber() {
    return this.etc4Obj ? this.etc4Obj.orderDetailNumber : "";
  }

  get productNames() {
    return this.productName ? this.productName.split(";;") : [];
  }
}
