import React, { useCallback } from "react";

import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";

import { useAuthStore } from "@/stores/hooks";

const BodyContent = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 1180px;
  padding: 0 20px;
  display: flex;
`;

const BannerWrap = styled.div`
  width: 750px;
`;
const Banner = styled.img`
  width: 100%;
`;

const FormWrap = styled.div`
  padding-left: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Logo = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;
const LogoImage = styled.img`
  height: 60px;
`;

const Title = styled.h2`
  font-size: 28px;
  margin-bottom: 5px;
`;
const SubTitle = styled.h5`
  font-weight: normal;
  margin-bottom: 20px;
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 10px;
`;

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

export default function Login() {
  const { ...actions } = useAuthStore();

  const { handleSubmit, errors, register } = useForm();

  const onSubmit = useCallback(
    async (data) => {
      try {
        await actions.signIn(data);

        window.location.href = "/deliveries";
      } catch (e) {
        alert(e.message);
      }
    },
    [actions],
  );

  return (
    <BodyContent>
      <Container>
        <BannerWrap>
          <Link
            target="_blank"
            to={{ pathname: "https://www.ezadmin.co.kr/sub_01_76.html" }}
          >
            <Banner
              alt="성공하는 쇼핑몰에는 이지어드민이 있다"
              src="/assets/images/banner.jpg"
            />
          </Link>
        </BannerWrap>
        <FormWrap>
          <Logo>
            <LogoImage
              alt="두발히어로 로고 이미지"
              src="/assets/images/logo.png"
            />
          </Logo>
          <Title>기업고객 전용 퀵서비스</Title>
          <SubTitle>기업회원 접수/조회용</SubTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Form.Control
                name="username"
                placeholder="사용자 ID를 입력하십시오"
                ref={register({ required: true })}
              />
              <ErrorText>
                {errors.username && "사용자 ID를 입력해주세요."}
              </ErrorText>
            </FormGroup>
            <FormGroup>
              <Form.Control
                name="password"
                type="password"
                placeholder="비밀번호를 입력하십시오"
                ref={register({ required: true })}
              />
              <ErrorText>
                {errors.password && "비밀번호를 입력해주세요."}
              </ErrorText>
            </FormGroup>
            <Button type="submit">로그인하기</Button>
          </Form>
        </FormWrap>
      </Container>
    </BodyContent>
  );
}
