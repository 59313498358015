import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Guide from "@/components/Guide";
import Table from "@/components/Table";

import PrintContent from "@/components/Print/PrintContent";
import PrintPortal from "@/components/Print/PrintPortal";

import { DeliveryForWaybill } from "@/Models/DeliveryForWaybill";

import DeliveryModal from "@/modals/DeliveryModal";

import { useDeliveriesStore, useModalStore } from "@/stores/hooks";
import { useDeliveriesForScanner } from "../store/deliveries-for-scanner";

import { SIZE_TYPE_150_V3 } from "@/utils/Print";

const BodyContent = styled.div``;

const Container = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
`;

const SearchWrap = styled.div`
  padding-top: 15px;
`;
const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const Column = styled(Col)`
  display: flex;
  align-items: center;
`;

const ColumnRight = styled(Column)`
  justify-content: flex-end;
`;

const SearchButton = styled(Button)`
  margin-left: 5px;
`;

const ResetButton = styled(Button)`
  margin-left: 5px;
`;

const FormControl = styled(Form.Control)`
  width: ${({ width }) => width || 180}px !important;
`;

const SearchLabel = styled.div`
  font-weight: bold;
  margin-right: 10px;
`;

const TableWrap = styled.div``;
const ColumnLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
`;

const BookIdColumn = memo(({ bookId }) => {
  const { openModal } = useModalStore();

  const handleClick = useCallback(async () => {
    openModal(<DeliveryModal key="delivery-modal" bookId={bookId} />);
  }, []);

  return <ColumnLink onClick={handleClick}>{bookId}</ColumnLink>;
});

export default function DeliveriesForScanner() {
  const columns = useMemo(
    () => [
      {
        Header: "사측주문번호",
        accessor: "orderIdFromCorp",
        id: "orderIdFromCorp",
        sort: true,
        width: 120,
      },
      {
        Header: "예약번호",
        accessor: (row) => {
          return <BookIdColumn bookId={row.bookId} />;
        },
        width: 120,
      },
      {
        Header: "접수일시",
        accessor: (row) => row.renderReceiptDate(),
        id: "receiptDate",
        sort: true,
        width: 105,
      },
      {
        Header: "접수자",
        accessor: "senderName",
        width: 105,
      },
      {
        Header: "고객명",
        accessor: "receiverName",
        width: 105,
      },
      {
        Header: "상품명",
        accessor: "productName",
        width: 120,
      },
      {
        Header: "기타사항1",
        accessor: "etc1",
        width: 100,
      },
      {
        Header: "기타사항2",
        accessor: "etc2",
        width: 100,
      },
      {
        Header: "기타사항3",
        accessor: "etc3",
        width: 100,
      },
      {
        Header: "수거완료",
        accessor: (row) => row.renderPickupDateCompleted(),
        width: 105,
      },
      {
        Header: "배송중",
        accessor: (row) => row.renderReleasedAt(),
        width: 105,
      },
      {
        Header: "배송완료",
        accessor: (row) => row.renderDeliveryCompletedDate(),
        id: "deliveryCompletedDate",
        sort: true,
        width: 105,
      },
      {
        Header: "취소",
        accessor: (row) => row.renderCanceledAt(),
        width: 105,
      },
      {
        Header: "사고",
        accessor: (row) => row.renderAccidentAt(),
        width: 105,
      },
      {
        Header: "미배송목록",
        accessor: "delayedDeliveries",
        width: 120,
      },
      {
        Header: "운송장출력여부",
        accessor: (row) => (row.printed ? "O" : "X"),
        width: 100,
      },
    ],
    [],
  );

  const textQueryInputRef = useRef(null);
  const { state, ...actions } = useDeliveriesStore();
  const { deliveries, textQuery, addDelivery, resetDeliveries, setTextQuery } =
    useDeliveriesForScanner();

  const [showPrintWindow, setShowPrintWindow] = useState(false);

  const { handleSubmit, reset } = useForm();

  //   const [selectedDeliveries, setSelectedDeliveries] = useState([]);

  useEffect(() => {
    reset({
      status: state.query.status,
    });

    textQueryInputRef.current.focus();
  }, []);

  const handleChangeTextQuery = useCallback(
    (e) => {
      setTextQuery(e.target.value);
    },
    [setTextQuery],
  );

  const handleClickHeader = useCallback(
    (orderBy) => {
      actions.setQuery({ ...orderBy });
    },
    [actions.setQuery],
  );

  const handleClickReset = useCallback(() => {
    setShowPrintWindow(false);
    resetDeliveries();
    textQueryInputRef.current.focus();
  }, []);

  /**
   * 운송장 출력
   */
  const handleClickPrint = useCallback(() => {
    if (deliveries.length > 0) {
      setShowPrintWindow(true);
    } else {
      alert("배송건을 조회해주세요.");
    }
  }, [deliveries]);

  const onSubmit = useCallback(() => {
    if (textQuery) {
      addDelivery(textQuery);
      setTextQuery("");
      textQueryInputRef.current.focus();
    }
  }, [addDelivery, textQuery]);

  return (
    <BodyContent>
      <Guide />
      <Container>
        <SearchWrap>
          <SearchRow>
            <ColumnRight>
              <Form inline onSubmit={handleSubmit(onSubmit)}>
                <FormControl
                  width={300}
                  name="text"
                  onChange={handleChangeTextQuery}
                  placeholder="예약번호, 수하인명, 사측주문번호 조회"
                  value={textQuery}
                  ref={textQueryInputRef}
                />
                <SearchButton type="submit" width="100px">
                  조회
                </SearchButton>
                <ResetButton
                  variant="outline-secondary"
                  type="button"
                  width="100px"
                  onClick={handleClickReset}
                >
                  초기화
                </ResetButton>
              </Form>
            </ColumnRight>
          </SearchRow>
          <SearchRow>
            <ColumnRight>
              <SearchLabel>운송장 출력</SearchLabel>
              <SearchButton
                onClick={handleClickPrint}
                type="button"
                variant="outline-secondary"
                width="80px"
              >
                출력하기
              </SearchButton>
            </ColumnRight>
          </SearchRow>
        </SearchWrap>

        <TableWrap>
          <Table
            responsive
            bordered
            hover
            columns={columns}
            currentPage={1}
            data={deliveries}
            onClickHeader={handleClickHeader}
            // onSelect={handleSelect}
          />
        </TableWrap>
      </Container>

      {showPrintWindow && (
        <PrintPortal sizeType={SIZE_TYPE_150_V3} onRefresh={handleClickReset}>
          <PrintContent
            deliveries={deliveries.map(
              (d) => new DeliveryForWaybill(d, SIZE_TYPE_150_V3),
            )}
            sizeType={SIZE_TYPE_150_V3}
          />
        </PrintPortal>
      )}
    </BodyContent>
  );
}
