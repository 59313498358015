import _uniq from "lodash/uniq";
import _uniqBy from "lodash/uniqBy";
import {
  atom,
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import Delivery from "@/Models/Delivery";
import { useCallback } from "react";

const beep = ({ type }) => {
  let audio = null;
  switch (type) {
    case 1:
      audio = new Audio("/assets/sounds/beep-01a.mp3");
      break;
    default:
      audio = new Audio("/assets/sounds/beep-02.mp3");
      break;
  }
  audio.play();
};

const textQueriesAtom = atom({
  key: "textQueriesAtom",
  default: [],
});

const textQueryAtom = atom({
  key: "textQueryAtom",
  default: "",
});

const deliveryQuery = selectorFamily({
  key: "deliveryQuery",
  get: (textQuery) => async () => {
    const result = await fetch(
      `${process.env.REACT_APP_API_URL}/deliveries/one?query=${textQuery}`,
      {
        credentials: "include",
        method: "GET",
        mode: "cors",
      },
    ).then((res) => res.json());

    return result;
  },
});

const deliveriesQuery = selector({
  key: "deliveriesQuery",
  get: ({ get }) => {
    const textQueries = get(textQueriesAtom);
    const deliveries = [];
    for (let i = 0; i < textQueries.length; i++) {
      const tq = textQueries[i];
      try {
        const result = get(deliveryQuery(tq));
        if (result.statusCode === 404) {
          if (i === textQueries.length - 1) {
            beep({ type: 1 });
            deliveries.push(null);
          }
        } else {
          deliveries.push(new Delivery(result));
        }
      } catch (_) {
        deliveries.push(null);
      }
    }

    const latestDelivery = deliveries[deliveries.length - 1];
    if (!!latestDelivery) {
      if (
        deliveries
          .filter((d) => !!d)
          .slice(0, deliveries.length - 1)
          .map((d) => d.bookId)
          .includes(latestDelivery.bookId)
      ) {
        beep({ type: 2 });
      }
    }

    return _uniqBy(
      deliveries.filter((d) => !!d),
      "bookId",
    );
  },
});

export const useDeliveriesForScanner = () => {
  const deliveries = useRecoilValue(deliveriesQuery);
  const textQueries = useRecoilValue(textQueriesAtom);

  const [textQuery, _setTextQuery] = useRecoilState(textQueryAtom);
  const setTextQueries = useSetRecoilState(textQueriesAtom);

  const addDelivery = useCallback(
    (textQuery) => {
      const query = textQuery.trim();
      if (query) {
        setTextQueries([...textQueries, query]);
      }
    },
    [deliveries],
  );

  const resetDeliveries = () => {
    setTextQueries([]);
  };

  const setTextQuery = (tq) => {
    _setTextQuery(tq);
  };

  return { deliveries, addDelivery, resetDeliveries, setTextQuery, textQuery };
};
