import React, { useCallback, useEffect } from "react";

import styled from "@emotion/styled";

import Popup from "@/modals/Popup";

import { useModalStore, usePopupsStore } from "@/stores/hooks";

const Footer = styled.div`
  background-color: #585756;
  padding: 10px 0;
`;
const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`;
const FooterText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 14px;
`;

export default function FooterComponent() {
  const { state: popupState, ...popupsActions } = usePopupsStore();
  const { openModal } = useModalStore();

  useEffect(() => {
    fetchPopups();
  }, []);

  const fetchPopups = useCallback(async () => {
    if (!popupState.readed) {
      try {
        const popups = await popupsActions.fetchAll();
        const prevPopups = await popupsActions.fetchStorage();

        if (popups?.length > 0) {
          popups.map((popup) => {
            const _prev = prevPopups.find((e) => e === popup.id);

            if (!_prev) {
              return openModal(
                <Popup key={`popup-${popup.id}-modal`} popup={popup} />,
              );
            } else {
              return null;
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [popupState.readed, popupsActions.fetchAll]);

  return (
    <Footer>
      <Container>
        <FooterText>
          ㈜체인로지스 서울특별시 용산구 독서당로 39, 1층 두발히어로
          물류센터(신성미소시티) 1566-0580
        </FooterText>
      </Container>
    </Footer>
  );
}
