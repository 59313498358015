import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useCookies } from "react-cookie";

import User from "@/Models/User";

import api from "../Api";

export const CONSTANTS = {
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  RESTORE: "RESTORE",
  RESTORE_FAILED: "RESTORE_FAILED",
};

const INITIAL_STATE = {
  user: null,
  token: null,
  restoring: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.SIGN_IN:
      return {
        ...state,
        user: action.user,
        token: action.token,
        restoring: false,
      };
    case CONSTANTS.SIGN_OUT:
      return {
        ...state,
        user: null,
        token: null,
      };
    case CONSTANTS.RESTORE:
      return {
        ...state,
        restoring: false,
        user: action.user,
      };
    case CONSTANTS.RESTORE_FAILED:
      return {
        ...state,
        restoring: false,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useAuthStore = () => {
  const [cookies, _setCookies, removeCookie] = useCookies(["token", "user"]);

  const { dispatch, state } = useContext(Context);

  const signIn = useCallback(
    (data) => {
      return api.post("/auth/signin", data).then((res) => {
        dispatch({
          type: CONSTANTS.SIGN_IN,
          user: new User(res.user),
          token: res.token,
        });
        return new User(res.user);
      });
    },
    [dispatch],
  );

  const signOut = useCallback(async () => {
    await api.post("/auth/signout");

    removeCookie("token");
    dispatch({ type: CONSTANTS.SIGN_OUT });

    return true;
  }, [dispatch]);

  const checkAlreadySignIn = useCallback(() => {
    return !!cookies.token;
  }, []);

  const signInExistingUser = useCallback(() => {
    dispatch({ type: CONSTANTS.SIGN_IN, token: cookies.token });
  }, []);

  const restore = useCallback(() => {
    return api
      .post("/auth/check-session")
      .then((res) => {
        dispatch({ type: CONSTANTS.RESTORE, user: new User(res.user) });
        return new User(res.user);
      })
      .catch(() => {
        dispatch({ type: CONSTANTS.RESTORE_FAILED });
      });
  });

  return {
    state,
    signIn,
    signOut,
    checkAlreadySignIn,
    signInExistingUser,
    restore,
  };
};
