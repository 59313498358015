import React, { memo, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import styled from "@emotion/styled";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "60px 20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#6c757d",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const Self = styled.div``;
const Text = styled.div``;

export default memo(({ accept = "", onUpload }) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = (e) => {
        onUpload(e.target.result);
      };

      reader.readAsBinaryString(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ accept, onDrop });
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  return (
    <Self {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <Text>파일을 끌어다 놓거나 클릭하세요.</Text>
    </Self>
  );
});
