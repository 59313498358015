import React from "react";
import ReactExport from "react-data-export";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";

import { formatDate } from "@/lib/date";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const TitleButton = styled(Button)`
  margin-left: 5px;
`;

export default function ExcelAfterBulkRegister({ data }) {
  return (
    <ExcelFile
      filename={`체인로지스_두발히어로_주문내역_${formatDate(
        new Date(),
        "MMdd",
      )}`}
      element={
        <TitleButton type="button" variant="outline-secondary" width="120px">
          접수 다운로드
        </TitleButton>
      }
    >
      <ExcelSheet data={data} name="Employees">
        <ExcelColumn label="예약번호" value="bookId" />
        <ExcelColumn label="사측주문번호" value="orderIdFromCorp" />
        <ExcelColumn label="송하인명" value="senderName" />
        <ExcelColumn label="송하인주소" value="senderAddress" />
        <ExcelColumn label="송하인상세주소" value="senderAddressDetail" />
        <ExcelColumn label="송하인연락처" value="senderMobile" />
        <ExcelColumn label="수하인명" value="receiverName" />
        <ExcelColumn label="수하인주소" value="receiverAddress" />
        <ExcelColumn label="수하인상세주소" value="receiverAddressDetail" />
        <ExcelColumn label="수하인연락처1" value="receiverMobile" />
        <ExcelColumn label="수하인연락처2" value="receiverMobile2" />
        <ExcelColumn label="고객요청사항" value="memoFromCustomer" />
        {/* <ExcelColumn label="결제방법" value="orderIdFromCorp" /> */}
        <ExcelColumn label="물품가격" value="productPrice" />
        <ExcelColumn label="물품명" value="productName" />
        <ExcelColumn label="물품분류" value="productCategory" />
        <ExcelColumn label="기타사항1" value="etc1" />
        <ExcelColumn label="기타사항2" value="etc2" />
        <ExcelColumn label="기타사항3" value="etc3" />
        <ExcelColumn label="지역그룹" value="receiverAllocationGroupName" />
        {/* <ExcelColumn label="Gender" value="sex" />
        <ExcelColumn
          label="Marital Status"
          value={(col) => (col.is_married ? "Married" : "Single")}
        /> */}
      </ExcelSheet>
    </ExcelFile>
  );
}
