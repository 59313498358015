import React, { useCallback, useEffect, useRef } from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";

import { useProfileStore } from "@/stores/hooks";

const BodyContent = styled.div``;
const Container = styled.div`
  width: 940px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
`;

const TitleWrap = styled(Row)`
  padding: 20px 0;
  margin: 0 0 20px;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;
const SectionTitle = styled.h4`
  font-size: 18px;
  padding-bottom: 5px;
  border-bottom: 1px solid #555;
  margin-bottom: 10px;
  font-weight: bold;
`;

const CorpInfo = styled.div`
  color: #555;
`;

const FormCol = styled(Col)``;
const FormColFlex = styled(FormCol)`
  align-items: center;
  display: flex;
`;
const FormLabel = styled(Form.Label)`
  font-weight: bold;
`;
const FormControl = styled(Form.Control)`
  width: ${({ width }) => width || "100%"};
`;
const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

export default function Profile() {
  const { user } = useProfileStore().state;
  const { ...actions } = useProfileStore();

  const { errors, handleSubmit, register, reset } = useForm();
  const {
    errors: errorsPassword,
    handleSubmit: handleSubmitPassword,
    register: registerPassword,
    watch: watchPassword,
    reset: resetPassword,
  } = useForm();

  const password = useRef({});
  password.current = watchPassword("password", "");

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    reset({
      autoPrint: user.autoPrint,
      contact: user.contact,
      realname: user.realname,
      username: user.username,
    });
  }, [user]);

  const fetchUser = useCallback(async () => {
    try {
      await actions.fetchUser();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await actions.update(data);
        alert("수정되었습니다.");
      } catch (e) {
        alert(e.message);
      }
    },
    [actions.update],
  );

  const onSubmitPassword = useCallback(
    async (data) => {
      try {
        await actions.updatePassword({
          old: data.currentPassword,
          new: data.password,
        });
        alert("수정되었습니다.");

        resetPassword();
      } catch (e) {
        alert(e.message);
      }
    },
    [actions.updatePassword],
  );

  return (
    <BodyContent>
      <Container>
        <TitleWrap>
          <Title>내 정보 수정하기</Title>
        </TitleWrap>
        <Section>
          <SectionTitle>연결된 접수점</SectionTitle>
          <CorpInfo>
            접수점: {user.spotName} / {user.spotAddress}{" "}
            {user.spotAddressDetail}
          </CorpInfo>
        </Section>
        <Section>
          <SectionTitle>내 정보 수정</SectionTitle>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group as={Row}>
              <FormLabel column xs="2">
                아이디
              </FormLabel>
              <FormCol>
                <FormControl
                  name="username"
                  readOnly
                  ref={register()}
                  width="400px"
                />
              </FormCol>
            </Form.Group>
            <Form.Group as={Row}>
              <FormLabel column xs="2">
                성명
              </FormLabel>
              <FormCol>
                <FormControl
                  name="realname"
                  ref={register({ required: true })}
                  width="400px"
                />
                <ErrorText>
                  {errors.realname && "성명을 입력해주세요."}
                </ErrorText>
              </FormCol>
            </Form.Group>
            <Form.Group as={Row}>
              <FormLabel column xs="2">
                연락처
              </FormLabel>
              <FormCol>
                <FormControl
                  name="contact"
                  placeholder="-를 빼고 입력해주세요."
                  ref={register({ required: true })}
                  width="400px"
                />
                <ErrorText>
                  {errors.contact && "연락처 입력해주세요."}
                </ErrorText>
              </FormCol>
            </Form.Group>
            <Form.Group as={Row}>
              <FormLabel column xs="2">
                운송장 자동 출력
              </FormLabel>
              <FormColFlex>
                <Form.Check
                  id="autoPrint"
                  name="autoPrint"
                  type="checkbox"
                  ref={register}
                />
              </FormColFlex>
            </Form.Group>
            <Form.Group as={Row}>
              <FormCol xs={{ span: 10, offset: 2 }}>
                <Button type="submit" width="180px">
                  등록
                </Button>
              </FormCol>
            </Form.Group>
          </Form>
        </Section>
        <Section>
          <SectionTitle>비밀번호 변경</SectionTitle>
          <Form onSubmit={handleSubmitPassword(onSubmitPassword)}>
            <Form.Group as={Row}>
              <FormLabel column xs="2">
                현재 비밀번호
              </FormLabel>
              <FormCol>
                <FormControl
                  name="currentPassword"
                  ref={registerPassword({ required: true })}
                  type="password"
                  width="400px"
                />
                <ErrorText>
                  {errorsPassword.currentPassword &&
                    "현재 비밀번호를 입력해주세요."}
                </ErrorText>
              </FormCol>
            </Form.Group>
            <Form.Group as={Row}>
              <FormLabel column xs="2">
                새 비밀번호
              </FormLabel>
              <FormCol>
                <FormControl
                  name="password"
                  ref={registerPassword({ required: true })}
                  type="password"
                  width="400px"
                />
                <ErrorText>
                  {errorsPassword.password && "새 비밀번호를 입력해주세요."}
                </ErrorText>
              </FormCol>
            </Form.Group>
            <Form.Group as={Row}>
              <FormLabel column xs="2">
                새 비밀번호 확인
              </FormLabel>
              <FormCol>
                <FormControl
                  name="passwordConfirm"
                  ref={registerPassword({
                    validate: (value) =>
                      value === password.current ||
                      "비밀번호가 일치하지 않습니다.",
                  })}
                  type="password"
                  width="400px"
                />
                <ErrorText>{errorsPassword.passwordConfirm?.message}</ErrorText>
              </FormCol>
            </Form.Group>
            <Form.Group as={Row}>
              <FormCol xs={{ span: 10, offset: 2 }}>
                <Button type="submit" width="180px">
                  등록
                </Button>
              </FormCol>
            </Form.Group>
          </Form>
        </Section>
      </Container>
    </BodyContent>
  );
}
