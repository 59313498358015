import React, { memo } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";
import JsBarcode from "react-barcode";

const PrintContainer = styled.div``;
const WaybillContainer = styled.div``;

/**
 * Style
 */
const PrintContainerStyle = {
  padding: 0,
  margin: 0,
};

const WaybillContainerStyle = {
  display: "block",
  margin: 0,
  padding: 0,
};

const SectionStyle = {
  position: "absolute",
  top: 0,
  width: "50%",
  height: "100%",
};

const DefaultStyle = {
  left: `5mm`,
  right: "5mm",
  position: "absolute",
  fontSize: `18px`,
  whiteSpace: "pre-wrap",
};

const DefaultComponent = ({ children, style = {} }) => {
  return <div style={{ ...DefaultStyle, ...style }}>{children}</div>;
};

const Section = ({ children, style = {} }) => {
  return <div style={{ ...SectionStyle, ...style }}>{children}</div>;
};

const Barcode = ({ bookId }) => {
  return (
    <DefaultComponent
      style={{
        display: "flex",
        top: "82mm",
        left: "5mm",
      }}
    >
      <div>접수번호</div>
      <div
        style={{
          width: "60mm",
          height: "15mm",
          marginLeft: "10mm",
        }}
      >
        <JsBarcode
          width={1.7}
          height={30}
          margin={0}
          textSize={5}
          value={bookId}
        />
      </div>
    </DefaultComponent>
  );
};

const DongCode = ({ dongCode }) => {
  return (
    <DefaultComponent
      style={{
        top: "5mm",
        left: "50mm",
        fontSize: "20px",
      }}
    >
      {dongCode}
    </DefaultComponent>
  );
};

const Logo = ({ onLoad = _noop }) => {
  return (
    <DefaultComponent
      style={{
        top: "5mm",
        width: "25mm",
      }}
    >
      <img
        onLoad={onLoad}
        src="https://partner.doobalhero.kr/static/doobal.png"
        alt="두발히어로"
        style={{ width: "100%" }}
      />
    </DefaultComponent>
  );
};

const LogoTitle = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: `15mm`,
        fontSize: "14px",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const Memo = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "67mm",
      }}
    >
      <b>요청사항</b> {children}
    </DefaultComponent>
  );
};

const Numbering = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        bottom: "5mm",
        left: "0mm",
        width: "100%",
        textAlign: "center",
        fontSize: "15px",
        fontWeight: "bold",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const ProductNames = ({ productNames = [] }) => {
  return (
    <DefaultComponent
      style={{
        top: "13mm",
        left: "2mm",
        fontSize: "15px",
        lineHeight: "7mm",
        whiteSpace: "pre-wrap",
        wordBreak: "break-all",
      }}
    >
      {productNames.join("\n")}
    </DefaultComponent>
  );
};

const ProductNamesTitle = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "5mm",
        left: "0mm",
        width: "100%",
        textAlign: "center",
        fontSize: "17px",
        fontWeight: "bold",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const ProductTotalCount = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        left: "none",
        top: "2mm",
        right: "5mm",
        fontSize: "11px",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const ReceiptDate = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "5mm",
        left: "35mm",
        fontSize: "10px",
      }}
    >
      {children}
    </DefaultComponent>
  );
};

const ReceiverAddress = ({ customerAddress = "", unrefinedAddress = "" }) => {
  return (
    <DefaultComponent
      style={{
        top: "40mm",
      }}
    >
      <b>주 소</b> {(unrefinedAddress || customerAddress).trim()}
    </DefaultComponent>
  );
};

const ReceiverMobile = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "33mm",
      }}
    >
      <b>연락처</b> {children}
    </DefaultComponent>
  );
};

const ReceiverName = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "27mm",
      }}
    >
      <b>받는분</b> {children}
    </DefaultComponent>
  );
};

const RestProductComponets = ({
  numOfLinesRestProductNames,
  restProductNames,
  totalProductCount,
  totalProductPage,
}) => {
  let productNamesComponents = [];
  for (
    let i = 0;
    i < restProductNames.length;
    i += 2 * numOfLinesRestProductNames
  ) {
    let index = i / (2 * numOfLinesRestProductNames);

    productNamesComponents.push(
      <WaybillContainer key={i} className="label" style={WaybillContainerStyle}>
        {/** 운송장 왼쪽 영역 */}
        <Section
          style={{
            left: 0,
            borderRight: "1px solid black",
          }}
        >
          <ProductTotalCount>
            ({totalProductCount}) {(index + 1) * 2}/{totalProductPage}
          </ProductTotalCount>
          <ProductNames
            productNames={restProductNames.slice(
              i,
              i + numOfLinesRestProductNames,
            )}
          />
          <Numbering>1/1</Numbering>
        </Section>
        {/** 운송장 오른쪽 영역 */}
        <Section
          style={{
            right: 0,
          }}
        >
          <ProductTotalCount>
            ({totalProductCount}) {(index + 1) * 2 + 1}/{totalProductPage}
          </ProductTotalCount>
          <ProductNames
            productNames={restProductNames.slice(
              i + numOfLinesRestProductNames,
              i + 2 * numOfLinesRestProductNames,
            )}
          />
          <Numbering>1/1</Numbering>
        </Section>
      </WaybillContainer>,
    );
  }

  return productNamesComponents;
};

const SenderName = ({ children }) => {
  return (
    <DefaultComponent
      style={{
        top: "20mm",
      }}
    >
      <b>보내는 분</b> {children}
    </DefaultComponent>
  );
};

const WaybillWrap = memo(({ delivery, handleLoaded }) => {
  // ToDo
  // 1. Numbering 숫자 표시

  return (
    <>
      <WaybillContainer className="label" style={WaybillContainerStyle}>
        {/** 운송장 왼쪽 영역 */}
        <Section
          style={{
            left: 0,
            borderRight: "1px solid black",
          }}
        >
          <Logo onLoad={handleLoaded} />
          <LogoTitle>저녁도착 시간배송 택배</LogoTitle>
          <ReceiptDate>
            접수일
            <br />
            {delivery.renderReceiptDate("yyyy/MM/dd")}
            <br />
            {delivery.renderReceiptDate("HH:mm")}
          </ReceiptDate>
          <DongCode dongCode={delivery.dongCode} />

          <SenderName>{delivery.displaySenderName}</SenderName>
          <ReceiverName>{delivery.displayReceiverName}</ReceiverName>
          <ReceiverMobile>{delivery.displayReceiverMobile}</ReceiverMobile>
          <ReceiverAddress
            customerAddress={delivery.customerAddress}
            unrefinedAddress={delivery.displayUnrefinedAddress}
          />
          <Memo>{delivery.memoFromCustomer}</Memo>
          <Barcode bookId={delivery.bookId} />
        </Section>

        {/** 운송장 오른쪽 영역 */}
        <Section
          style={{
            right: 0,
          }}
        >
          <ProductNamesTitle>상품정보</ProductNamesTitle>
          <ProductNames productNames={delivery.firstProductNames} />
          <ProductTotalCount>
            ({delivery.renderTotalProductCount()}) 1/{delivery.totalProductPage}
          </ProductTotalCount>
          <Numbering>1/1</Numbering>
        </Section>
      </WaybillContainer>

      <RestProductComponets
        numOfLinesRestProductNames={delivery.numOfLinesRestProductNames}
        restProductNames={delivery.restProductNames}
        totalProductCount={delivery.renderTotalProductCount()}
        totalProductPage={delivery.totalProductPage}
      />
    </>
  );
});

export default function Print100200Component({
  deliveries = [],
  onLoaded = _noop,
}) {
  return (
    <PrintContainer style={PrintContainerStyle}>
      {deliveries.map((d, index) => {
        return (
          <WaybillWrap
            key={index}
            delivery={d}
            count={deliveries.length - index}
            totalCount={deliveries.length}
            handleLoaded={onLoaded}
          />
        );
      })}
    </PrintContainer>
  );
}
