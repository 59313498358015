import BaseModel from "./base-model";

export const USER_NAME_CHAINLOGIS = "chainlogis";
export const USER_NAME_CHAINLOGISAPITEST = "chainlogisapitest";
export const USER_NAME_SK7MOBILE = "sk7mobile";
export const USER_NAME_LGHELLOVISION = "lghellovision";
export const USER_NAME_ZION_USIM = "zion_usim";
export const USER_NAME_DRX = "drx";
export const USER_NAME_THEROOTS3 = "theroots3";

export default class User extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  get editableByWorksheet() {
    return !!this.etcObj?.editableByWorksheet || false;
  }

  get etcObj() {
    try {
      return this.etc && JSON.parse(this.etc);
    } catch (e) {
      return {};
    }
  }

  get useMasking() {
    return !!this.etcObj?.useMasking || false;
  }

  get spotName() {
    return this.spots.length ? this.spots[0].name : "";
  }

  get spotCode() {
    return this.spots.length ? this.spots[0].code : "";
  }

  get spotAddress() {
    return this.spots.length ? this.spots[0].address : "";
  }

  get spotAddressDetail() {
    return this.spots.length ? this.spots[0].addressDetail : "";
  }

  get updatable() {
    return (
      this.username === USER_NAME_CHAINLOGIS ||
      this.username === USER_NAME_CHAINLOGISAPITEST ||
      this.username === USER_NAME_SK7MOBILE ||
      this.username === USER_NAME_LGHELLOVISION ||
      this.username === USER_NAME_ZION_USIM ||
      this.username === USER_NAME_DRX ||
      this.username === USER_NAME_THEROOTS3
    );
  }
}
