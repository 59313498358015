import React, { useCallback } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

import styled from "@emotion/styled";

import { useAuthStore } from "@/stores/hooks";

const Container = styled.div`
  width: 1240px;
  margin: 0 auto;
  padding: 10px 0;
`;
const LogoImage = styled.img`
  height: 40px;
`;

const NavLink = styled(Link)`
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  display: block;
  padding: 0.5rem 1rem;
`;

const NavLinkBoot = styled(Nav.Link)`
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-weight: bold;
`;

export default function NavComponent({ scanner }) {
  const { ...actions } = useAuthStore();

  const signOut = useCallback(() => {
    actions.signOut();
  }, [actions.signOut]);

  return (
    <Container>
      <Navbar>
        <Navbar.Brand href="/deliveries">
          <LogoImage
            alt="두발히어로 로고 이미지"
            src="/assets/images/logo.png"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" />
          {scanner ? (
            <Nav>
              <NavLink to="/deliveries-for-scanner">
                운송장 출력 전용(스캐너)
              </NavLink>
              <NavLink onClick={signOut}>로그아웃</NavLink>
            </Nav>
          ) : (
            <Nav>
              <NavLink to="/deliveries">조회</NavLink>
              <NavLink to="/deliveries-new">배송접수</NavLink>
              <NavLink to="/profile">내정보</NavLink>
              <NavLinkBoot onClick={signOut}>로그아웃</NavLinkBoot>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}
