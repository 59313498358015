import React, { useCallback } from "react";

import styled from "@emotion/styled";

import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";

import { useModalStore, usePopupsStore } from "@/stores/hooks";

const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-left: -10px;
  margin-right: -10px;
`;

const ButtonWrap = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
`;
const ModalButton = styled(Button)`
  margin-left: 5px;
`;

const FormLabel = styled(Form.Label)`
  margin-bottom: 0;
  margin-right: 15px;
  display: flex;
  align-items: center;
`;

const FormCheck = styled(Form.Check)`
  margin-left: 5px;
`;

const Image = styled.img`
  width: 100%;
`;

export default function PopupModal({ popup }) {
  const { ...actions } = usePopupsStore();
  const { closeModal } = useModalStore();

  const { register, getValues } = useForm();

  const onClose = useCallback(() => {
    const checked = getValues("checked");

    if (checked) {
      actions.setStorage(popup.id);
      closeModal();
    } else {
      actions.setReaded();
      closeModal();
    }
  }, [actions.setReaded, popup]);

  return (
    <Modal size="sm">
      <ModalBody>
        {popup.image && <Image src={popup.image} />}
        <ButtonWrap>
          <FormLabel>
            다시 보지 않음
            <FormCheck name="checked" type="checkbox" ref={register} />
          </FormLabel>
          <ModalButton
            onClick={onClose}
            type="button"
            variant="outline-secondary"
            width="120px"
          >
            닫기
          </ModalButton>
        </ButtonWrap>
      </ModalBody>
    </Modal>
  );
}
