import React from "react";

import styled from "@emotion/styled";
import Spinner from "react-bootstrap/Spinner";

import { useLoadingStore } from "@/stores/hooks";

const LoadingBackdrop = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
`;

const Loading = () => {
  const { loading } = useLoadingStore().state;

  if (!loading) {
    return null;
  }

  return (
    <LoadingBackdrop>
      <Spinner animation="border" variant="light" />
    </LoadingBackdrop>
  );
};

export default Loading;
