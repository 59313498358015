import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";

import styled from "@emotion/styled";

import Nav from "@/components/Nav";
import Footer from "@/components/Footer";
import LoadingComponent from "@/components/Loading";

import { useAuthStore, useLoadingStore, useModalStore } from "@/stores/hooks";

import routes from "./routes";

const Page = styled.div`
  min-width: 1240px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default function Router() {
  const { user, restoring } = useAuthStore().state;
  const { ...authActions } = useAuthStore();

  const { loading } = useLoadingStore().state;

  const { modals } = useModalStore().state;

  const history = useHistory();

  const [fromReferer] = useState(history.location.pathname || "/");

  useEffect(async () => {
    if (authActions.checkAlreadySignIn()) {
      authActions.signInExistingUser();
      history.push(fromReferer);
    } else {
      try {
        await authActions.restore();
        history.push(fromReferer);
      } catch (_err) {
        history.push("/");
      }
    }
  }, []);

  return !restoring ? (
    <>
      <Switch>
        {routes.map((route) => (
          <Route exact={route.exact} key={route.name} path={route.path}>
            {route.private && !user && <Redirect to="/" />}

            {((route.private && user) || !route.private) && (
              <Page>
                {route.nav && (
                  <Nav scanner={route.name === "DeliveriesForScanner"} />
                )}
                <Content>
                  <route.component />
                </Content>
                <Footer />
              </Page>
            )}
          </Route>
        ))}
      </Switch>
      {modals.map((modal) => modal)}
      {loading && <LoadingComponent />}
    </>
  ) : (
    <></>
  );
}
