import Deliveries from "@/pages/Deliveries";
import DeliveriesNew from "@/pages/DeliveriesNew";
import Login from "@/pages/Login";
import Profile from "@/pages/Profile";
import DeliveriesForScanner from "./pages/DeliveriesForScanner";

const routes = [
  {
    component: Deliveries,
    exact: true,
    name: "Deliveries",
    nav: true,
    path: "/deliveries",
    private: true
  },
  {
    component: DeliveriesNew,
    exact: true,
    name: "DeliveriesNew",
    nav: true,
    path: "/deliveries-new",
    private: true
  },
  {
    component: Login,
    exact: true,
    name: "Login",
    path: "/"
  },
  {
    component: Profile,
    exact: true,
    name: "Profile",
    nav: true,
    path: "/profile",
    private: true
  },
  {
    component: DeliveriesForScanner,
    exact: true,
    name: "DeliveriesForScanner",
    nav: true,
    path: "/deliveries-for-scanner",
    private: true
  },
];

export default routes;
