import React from "react";
import OriginButton from "react-bootstrap/Button";

import styled from "@emotion/styled";

const ButtonNormal = styled(OriginButton)`
  width: 100%;
  width: ${({ width }) => width};

  ${({ variant }) => variant === 'default' ? `
    background-color: #ea2845;
    border-color: #ea2845;
    color: #fff;

    &:hover, &:focus, &:active, &:not(:disabled):not(.disabled):active {
      color: #fff;
      background-color: #b81c32;
      border-color: #b81c32;
    }
  ` : ''}
`

export const Button = ({ children, variant = "default", width = "100%", ...restProps }) => (
  <ButtonNormal variant={variant} width={width} {...restProps}>
    {children}
  </ButtonNormal>
);
