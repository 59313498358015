import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import User from "@/Models/User";

import api from "../Api";

export const CONSTANTS = {
  FETCH_USER: "FETCH_USER",
};

const INITIAL_STATE = {
  user: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_USER:
      return {
        ...state,
        user: action.user,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useProfileStore = () => {
  const { dispatch, state } = useContext(Context);

  const fetchUser = useCallback(async () => {
    const user = await api.get("/me");

    console.log("a", user);
    dispatch({
      type: CONSTANTS.FETCH_USER,
      user: new User(user),
    });
  }, [dispatch]);

  const update = useCallback(
    async ({ autoPrint, contact, realname }) =>
      await api.put(`/me`, { autoPrint, contact, realname }),
    [],
  );

  const updatePassword = useCallback(
    async (data) => await api.put(`/me/password`, data),
    [],
  );

  return {
    state,
    fetchUser,
    update,
    updatePassword,
  };
};
