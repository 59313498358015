import React from "react";

import { Link } from "react-router-dom";

import styled from "@emotion/styled";

const Guide = styled.div`
  color: #fff;
  text-align: center;
  background-color: #585756;
  padding: 10px 0;
`
const GuideText = styled.div`
  font-size: 14px;
`

export default function GuideComponent() {
  return (
    <Guide>
      <Link
        target="_blank"
        to={{ pathname: "https://drive.google.com/file/d/10Uv-2j1K09IIPijwcknwra523--ibfyv/view" }} 
      >
        <GuideText>
          두발히어로 이용방법 안내(클릭하세요) - 단건 접수방법, 대량 접수방법 등 이용방법을 확인해보세요.
        </GuideText>
      </Link>
    </Guide>
  )
};

