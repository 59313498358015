import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import api from "../Api";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  FETCH_STORAGE: "FETCH_STORAGE",
  SET_READED: "SET_READED",
};

const INITIAL_STATE = {
  fetched: false,
  readed: false,
  popups: [],
  prevPopups: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        popups: action.popups,
      };
    case CONSTANTS.FETCH_STORAGE:
      return {
        ...state,
        prevPopups: action.prevPopups,
      };
    case CONSTANTS.SET_READED:
      return { ...state, readed: true };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const usePopupsStore = () => {
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const { rows } = await api.get("/popups/all");

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      popups: rows,
    });

    return rows;
  }, [dispatch]);

  const setReaded = useCallback(() => {
    dispatch({
      type: CONSTANTS.SET_READED,
    });
  }, [dispatch]);

  const fetchStorage = useCallback(() => {
    const _popups = localStorage.getItem("popups");
    const _popupsArr = _popups ? JSON.parse(_popups) : [];

    dispatch({
      type: CONSTANTS.FETCH_STORAGE,
      prevPopups: _popupsArr,
    });

    return _popupsArr;
  }, [dispatch]);

  const setStorage = useCallback((id) => {
    const _popups = localStorage.getItem("popups");
    const _popupsArr = _popups ? JSON.parse(_popups) : [];

    localStorage.setItem("popups", JSON.stringify([..._popupsArr, id]));
  }, []);

  return {
    state,
    fetchAll,
    fetchStorage,
    setReaded,
    setStorage,
  };
};
