import BaseModel from "./base-model";
import { renderStatus, parseDeliveryStatus } from "./Delivery";

import { formatDate, getDateByTimeZone } from "@/lib/date";

export default class DeliveryStatusLog extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  get afterString() {
    return renderStatus(parseDeliveryStatus(this.after));
  }

  get beforeString() {
    return renderStatus(parseDeliveryStatus(this.before));
  }

  renderCreatedAt(dateFormat = "MM/dd HH:mm") {
    return (
      this.createdAt &&
      formatDate(getDateByTimeZone(this.createdAt), dateFormat)
    );
  }
}
