import React from "react";

import {
  SIZE_TYPE_150_V3,
  SIZE_TYPE_150_V2,
  SIZE_TYPE_200,
} from "@/utils/Print";

import W100_H150_V3 from "./100_150_v3";
import W100_H150_V2 from "./100_150_v2";
import W100_H200 from "./100_200";

export default function PrintContentComponent({
  sizeType = SIZE_TYPE_150_V3,
  ...restProps
}) {
  switch (sizeType) {
    case SIZE_TYPE_150_V3:
      return <W100_H150_V3 {...restProps} />;
    case SIZE_TYPE_150_V2:
      return <W100_H150_V2 {...restProps} />;
    case SIZE_TYPE_200:
      return <W100_H200 {...restProps} />;
    default:
      return <W100_H150_V3 {...restProps} />;
  }
}
