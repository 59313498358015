import React, { memo } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";

const PaginationCol = styled(Col)`
  display: flex;
  align-items: center;
`
const PaginationButton = styled(Button)`
  width: auto;
`

export default memo(
  ({
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    pageCount,
    pageIndex,
    pageSize,
    previousPage,
    setPageSize
  }) => {
    function handleChangeSelectPageCount(e) {
      setPageSize(Number(e.target.value));
    }

    function handleClickFirstPage() {
      gotoPage(1);
    }

    function handleClickLastPage() {
      gotoPage(pageCount);
    }

    function handleClickNextPage() {
      nextPage();
    }

    function handleClickPreviousPage() {
      previousPage();
    }

    return (
      <Row>
        <PaginationCol xs={2}>
          <ButtonGroup>
            <PaginationButton onClick={handleClickFirstPage} disabled={!canPreviousPage}>
              {"<<"}
            </PaginationButton>{" "}
            <PaginationButton
              onClick={handleClickPreviousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </PaginationButton>{" "}
          </ButtonGroup>
        </PaginationCol>
        <PaginationCol xs={2}>
          페이지&nbsp;
          <strong>
            {pageIndex + 1}/{pageCount}
          </strong>{" "}
        </PaginationCol>
        <PaginationCol xs={2}>
          <ButtonGroup>
            <PaginationButton onClick={handleClickNextPage} disabled={!canNextPage}>
              {">"}
            </PaginationButton>{" "}
            <PaginationButton onClick={handleClickLastPage} disabled={!canNextPage}>
              {">>"}
            </PaginationButton>{" "}
          </ButtonGroup>
        </PaginationCol>
        <PaginationCol xs={{ span: 2, offset: 4 }}>
          <Form.Control
            as="select"
            value={pageSize}
            onChange={handleChangeSelectPageCount}
          >
            {[5, 10, 20, 25, 50, 100, 500, 1000].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}개씩 보기
              </option>
            ))}
          </Form.Control>
        </PaginationCol>
      </Row>
    );
  }
);
