import React, { createContext, useReducer, useContext } from "react";
import { useCallback } from "react";

export const CONSTANTS = {
  FINISH: "FINISH",
  START: "START",
};

const INITIAL_STATE = {
  loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FINISH:
      return {
        ...state,
        loading: false,
        loadingMessage: "",
      };
    case CONSTANTS.START:
      return {
        ...state,
        loading: true,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useLoadingStore = () => {
  const { dispatch, state } = useContext(Context);

  const finishLoading = useCallback(() => {
    dispatch({
      type: CONSTANTS.FINISH,
    });
  }, []);

  const startLoading = useCallback(() => {
    dispatch({
      type: CONSTANTS.START,
    });
  }, []);

  return {
    state,
    finishLoading,
    startLoading,
  };
};
